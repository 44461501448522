<template>
    <div class="authorization-forms">
        <CustomerForgotPasswordPanel
            v-if="isResetPasswordForm"
            :initial-email="initialEmail"
            :with-close-button="withCloseButton"
            :can-back-to-magic-link-sent-information="
                canBackToMagicLinkSentInformation
            "
            @back="$emit('reset-password-back-click')"
            @reset-success="onResetPasswordSuccess($event)"
            @close="$emit('close')"
            @reset-password-submit="$emit('reset-password-submit')"
            @click-send-magic-link-button="
                $emit('click-send-magic-link-button')
            "
            @magic-link-sent="$emit('magic-link-sent')"
            @magic-link-missing-link-click="
                $emit('magic-link-missing-link-click')
            "
            @magic-link-show-sent-information="
                $emit('magic-link-show-sent-information')
            "
            @magic-link-go-to-home="$emit('magic-link-go-to-home')"
            @magic-link-back-to-login="$emit('magic-link-back-to-login')"
            @magic-link-show-missing-link-information="
                $emit('magic-link-show-missing-link-information')
            "
        />
        <template v-else>
            <div class="tabs">
                <SegmentedControl
                    :selected="formId"
                    @select="selectTab($event)"
                >
                    <template #default="{ props, on }">
                        <SegmentedControlButton
                            v-for="{ id, title } in TABS"
                            :key="id"
                            :value="id"
                            v-bind="props"
                            class="segmented-button"
                            v-on="on"
                        >
                            {{ title }}
                        </SegmentedControlButton>
                    </template>
                </SegmentedControl>
            </div>
            <div class="forms">
                <transition name="fade" mode="out-in">
                    <component
                        :is="tabComponent"
                        :with-benefits="withRegisterBenefits"
                        :initial-email="initialEmail"
                        @login-success="onLoginSuccess($event)"
                        @register-success="$emit('register-success', $event)"
                        @register-click="$emit('register-click')"
                        @social-login-click="
                            $emit('social-login-click', $event)
                        "
                        @forget-password="setFormId(FORGOT_PASSWORD_FORM_ID)"
                        @login-click="$emit('login-click')"
                    />
                </transition>
            </div>
        </template>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA } from '@configs/modals';

import {
    LOGIN_COMPONENT_NAME,
    REGISTER_COMPONENT_NAME,
    AUTHORIZATION_FORMS_IDS,
} from '@types/CustomerModal';

import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import Loader from '@atoms/Loader/Loader';

import {
    SegmentedControl,
    SegmentedControlButton,
} from '@eobuwie-ui/components/SegmentedControl/v1';

const { mapState: mapStateCustomer } = createNamespacedHelpers('customer');
const { mapActions: mapActionsMessages } = createNamespacedHelpers('messages');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'AuthorizationForms',

    components: {
        SegmentedControl,
        SegmentedControlButton,
        CustomerLoginPanel: () => ({
            /* webpackChunkName: "customer-login-panel" */
            component: import(
                '@organisms/CustomerLoginPanel/CustomerLoginPanel'
            ),

            loader: Loader,
        }),

        CustomerRegisterPanel: () => ({
            /* webpackChunkName: "customer-register-panel" */
            component: import(
                '@organisms/CustomerRegisterPanel/CustomerRegisterPanel'
            ),

            loader: Loader,
        }),

        CustomerForgotPasswordPanel: () => ({
            /* webpackChunkName: "customer-forgot-password-panel" */
            component: import(
                '@organisms/CustomerForgotPasswordPanel/CustomerForgotPasswordPanel'
            ),

            loader: Loader,
        }),
    },

    props: {
        formId: {
            type: String,
            default: AUTHORIZATION_FORMS_IDS.LOGIN,
            validator: formId =>
                Object.values(AUTHORIZATION_FORMS_IDS).includes(formId),
        },

        withRegisterBenefits: {
            type: Boolean,
            default: false,
        },

        initialEmail: {
            type: String,
            default: '',
        },

        withCloseButton: {
            type: Boolean,
            default: true,
        },

        canBackToMagicLinkSentInformation: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapStateCustomer(['isCustomerSubscribedToNewsletter']),

        ...mapConfigGetters(['storeCode']),

        tabComponent() {
            return this.TABS_FORMS[this.formId] || null;
        },

        isResetPasswordForm() {
            return this.formId === AUTHORIZATION_FORMS_IDS.FORGOT_PASSWORD;
        },

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },
    },

    beforeCreate() {
        const { LOGIN, REGISTER, FORGOT_PASSWORD } = AUTHORIZATION_FORMS_IDS;

        this.FORGOT_PASSWORD_FORM_ID = FORGOT_PASSWORD;

        this.TABS_FORMS = {
            [LOGIN]: LOGIN_COMPONENT_NAME,
            [REGISTER]: REGISTER_COMPONENT_NAME,
        };

        this.TABS = [
            {
                title: this.$t('I have an account'),
                id: LOGIN,
            },
            {
                title: this.$t('I dont have an account'),
                id: REGISTER,
            },
        ];
    },

    methods: {
        ...mapActionsMessages(['addSuccessMessage']),

        onResetPasswordSuccess(email) {
            this.$emit('reset-password-success');

            this.addSuccessMessage({
                text: this.$t(
                    'We have sent a password reset link to the address {email}',
                    { email }
                ),
            });
        },

        async onLoginSuccess({ isRegistration }) {
            if (isRegistration) {
                if (
                    !this.isCustomerSubscribedToNewsletter ||
                    this.isLoyaltyClubEnabled
                ) {
                    this.$modals.open(MODAL_AFTER_REGISTRATION_BY_SOCIAL_MEDIA);
                }
            }

            this.$emit('login-success', { isRegistration });
        },

        selectTab(id) {
            if (this.formId === id) {
                return;
            }

            this.setFormId(id);
        },

        setFormId(id) {
            this.$emit('set-form-id', id);
        },
    },
};
</script>

<style lang="scss" scoped>
.authorization-forms {
    @apply relative;

    .forms {
        @apply mx-ui-4;
    }

    .tabs {
        @apply flex items-center mx-ui-4 my-ui-6;
    }

    .segmented-button {
        @apply flex-grow;
    }

    @screen lg {
        .tabs {
            @apply mx-ui-7;
        }

        .forms {
            @apply mx-ui-7;
        }
    }
}
</style>
